import React from 'react';

const AboutUs = () => {
    return (
        <div id="about-us" className="block">
            <div className="wrapper">
                <div className="row gutter-width-md with-pb-md">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                        <div className="description text-secondary">
                            <p>Welcome to A & A Group of Industries, a legacy spanning over six decades, originating in 1960 with the foresight of Haji M. Sharif (Late), the father of our present directors. From humble beginnings as a small machinery engineering workshop, Haji M. Sharif's dedication brought his vision to fruition through years of hard work.</p>
                        </div>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="description">
                            <p>A pivotal moment came in 1979 when Haji M. Sharif's eldest son, M. Amjad Sharif, joined the company. After more than a decade of growth, Arshad Sharif joined the company in 1990. This marked a turning point as the company ventured into trading steel commodities, aligning with Pakistan Steel Mill for added strength.  </p>
                            <p>The company thrived as an importer from 1990 to 2010 before diversifying into manufacturing steel pipes in 2010 and subsequently expanding into steel profiles. Notably, 2020 saw a leap into paper manufacturing using cutting-edge technology, with a focus on high-quality production through an imported unit. </p>
                            <p>A & A Group of Industries is more than a business; it's a testament to resilience, innovation, and a commitment to excellence that has defined us for over 60 years. Our journey reflects a continuous pursuit of growth and adaptation, laying the foundation for a promising future.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
