import React from 'react';

const Footer = () => {
    const path = window.location.pathname;

    return (
        <footer id="footer" className="site-footer">
            <div className="wrapper">
                <div className="d-flex flex-column flex-md-row flex-lg-row flex-xl-row align-items-center justify-content-between">
                    <div className="logo logo-primary d-flex" style={{ display: (path === '/404' ? 'none' : 'block'), marginBottom: '10px' }}>
                        <img src="assets/img/logo/logo-dark.svg" alt="Logo" />
                        <div className='socials'>
                            <a href='https://www.facebook.com' target='_blank' rel="noopener noreferrer">
                                <img style={{ marginRight: '10px' }} src='assets/img/socials/facebook.png' alt='facebook' />
                            </a>
                            <a href='https://www.instagram.com/aandagroupofindustry' target='_blank' rel="noopener noreferrer">
                                <img src='assets/img/socials/instagram.png' alt='instagram' />
                            </a>
                        </div>
                    </div>

                    <div className="d-flex">
                        <div className="align-self-center">
                            <div className="copyright">
                                <p>© A&A Group of Industries {new Date().getFullYear()}. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
