import React, { Component } from 'react';
import emailjs from '@emailjs/browser';

class ContactsForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            values: {
                name: '',
                phone: '',
                email: '',
                message: ''
            },
            successMessage: "Message Sent. Out representatiove will get back to you soon!",
            errorMessage: 'Something go wrong. Try again later!',
            alertClass: '',
            responseMessage: '',
            alertTimeout: '',
            delay: 5000,
        };
    };

    submitForm = async (e) => {
        e.preventDefault();

        if (document.querySelector('#alert')) {
            document.querySelector('#alert').remove();
        }

        this.setState({ isSubmitting: true });
        emailjs.send('service_p8x6avx', 'template_9uqpjgm', {
            from_name: e.target.name.value,
            phone: e.target.phone.value,
            message: e.target.message.value,
            reply_to: e.target.email.value
        }, 'Gy58-B4LMERnD_nR0')
            .then((response) => {
                if (response.status === 200) {
                    this.setState({ responseMessage: this.state.successMessage });
                } else {
                    this.setState({ responseMessage: this.state.errorMessage });
                }
                this.setState({ isSubmitting: false });
            }).catch((error) => {
                this.setState({ isSubmitting: false });
                this.callAlert(this.state.errorMessage, 'error');
            });
    };

    removeAlert = () => {
        clearTimeout(this.state.alertTimeout);

        this.setState({
            alertTimeout: setTimeout(function () {
                var element = document.querySelector('#alert');

                element.classList.remove('fadeIn');
                element.classList.add('fadeOut');

                setTimeout(function () {
                    element.remove();
                }, 900);
            }, this.state.delay),
        });
    };

    callAlert = (message, type) => {
        if (!document.querySelector('#alert')) {
            if (type === 'success') {
                this.setState({ alertClass: 'success' });
            }

            if (type === 'error') {
                this.setState({ alertClass: 'danger' });
            }

            if (type === 'warning') {
                this.setState({ alertClass: 'warning' });
            }

            var alert = '<div id="alert" class="animated fadeIn alert alert--shadow alert-' + this.state.alertClass + '">' + message + '</div>';

            var element = document.querySelector('#cf-1');

            element.insertAdjacentHTML('beforeend', alert);

            this.removeAlert();
        }
    };

    handleInputChange = (e) =>
        this.setState({
            values: {
                ...this.state.values,
                [e.target.name]: e.target.value,
            },
        });

    render() {
        return (
            <form onSubmit={this.submitForm} id="cf-1" className="contact-form">
                <div className="form-group">
                    <p className="input-group gutter-width-md no-space">
                        <span className="gutter-width">
                            <label htmlFor="cf-1-name" className="form-label-lg before">Name</label>
                            <input value={this.state.values.name} onChange={this.handleInputChange} name="name" type="text" id="cf-1-name" required="required" className="form-control font-size-lg" />
                        </span>

                        <span className="gutter-width">
                            <label htmlFor="cf-1-email" className="form-label-lg before">Email</label>
                            <input value={this.state.values.email} onChange={this.handleInputChange} name="email" type="email" id="cf-1-email" required="required" className="form-control font-size-lg" />
                        </span>
                    </p>
                </div>

                <div className="form-group">
                    <label htmlFor="cf-1-phone" className="form-label-lg before">Phone no.</label>
                    <input value={this.state.values.phone} onChange={this.handleInputChange} name="phone" type="text" id="cf-1-phone" required="required" className="form-control font-size-lg" />
                </div>

                <div className="form-group">
                    <label htmlFor="cf-1-message" className="form-label-lg before">Message</label>
                    <textarea value={this.state.values.message} onChange={this.handleInputChange} name="message" id="cf-1-message" required="required" className="form-control font-size-lg"></textarea>
                </div>

                <div className="form-group mb-0">
                    <button disabled={this.state.isSubmitting || this.state.responseMessage.length > 0} type="submit" className="btn btn-secondary">{this.state.responseMessage.length > 0 ? 'Submitted' : (this.state.isSubmitting ? 'Submitting...' : 'Submit')}</button>
                </div>
                {this.state.responseMessage.length > 0 && (<p style={{ color: 'green' }}>{this.state.responseMessage}</p>)}
            </form>
        );
    };
};

export default ContactsForm;
