import React from 'react';

import ModalData from '../../data/modal/modalData.json';

const ModalMenuSecondary = () => {
    return (
        <nav className="menu-secondary">
            <ul className="nav">
                {ModalData && ModalData.map((item, key) => {
                    return (
                        <li key={key} className="nav-item">
                            <a target='_blank' rel="noopener noreferrer" title={item.title} href={item.link} style={{ color: '#ffffff' }}><i className={item.icon}></i></a>
                        </li>
                    )
                })}
            </ul>
        </nav>
    );
};

export default ModalMenuSecondary;
