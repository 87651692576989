import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Contacts from './pages/Contacts';
import Steel from './pages/Steel';
import page404 from './pages/404';
// import UserInterface from './pages/UserInterface';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path={`${process.env.PUBLIC_URL + '/'}`} component={Home} />
        <Route exact path={`${process.env.PUBLIC_URL + '/steel'}`} component={Steel} />
        <Route exact path={`${process.env.PUBLIC_URL + '/about'}`} component={About} />
        <Route exact path={`${process.env.PUBLIC_URL + '/contact'}`} component={Contacts} />
        {/* <Route exact path={`${process.env.PUBLIC_URL + '/ui'}`} component={UserInterface} /> */}
        <Route exact component={page404} />
      </Switch>
    </Router>
  );
}

export default App;
