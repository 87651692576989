import React from 'react';

const PageTitle = () => {
    return (
        <section id="page-title" className="block">
            <div className="wrapper">
                <div className="row gutter-width-lg">
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                        <div className="title">
                            <h1 className="large">Scripting Success: Your Stories Begin with Our Finest Paper.</h1>
                        </div>
                    </div>
                </div>

                <div className="row gutter-width-lg with-pb-lg spacer p-top-lg">
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                        <div className="d-flex align-items-end h-100">
                            <a title="Contact" href='https://wa.me/+923324999266' rel="noopener noreferrer" target='_blank' className="btn btn-secondary">Contact Now</a>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                        <div className="description">
                            <p>Welcome to A&A, where innovation meets sustainability in the world of paper manufacturing. As a leading force in the industry, we take pride in crafting tomorrow's solutions today, one sheet at a time. Our commitment to excellence is woven into every fiber, ensuring top-quality paper products that not only meet your needs but also contribute to a greener, more sustainable future.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PageTitle;
