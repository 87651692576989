import React from 'react';
import ContactsForm from '../../components/form/ContactsForm';

const Contacts = () => {
    return (
        <div className="row gutter-width-md with-pb-xl">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="row gutter-width-md with-pb-lg">
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                        <div className="contacts">
                            <div className="contacts-items">
                                <div className="contacts-item">
                                    <div className="contacts-item-title">
                                        <h6>Locations</h6>
                                    </div>

                                    <div className="contacts-item-description">
                                        <p><strong>Mangement Office: </strong>99 Railway Road, Naulakha, lahore, Lahore.</p>
                                    </div>
                                    <div className="contacts-item-description">
                                        <p><strong>Factory: </strong>23-KM Sheikhupura Road. Mouza Killa. Bahind Siddique CNG, Lahore.</p>
                                    </div>
                                </div>

                                <div className="contacts-item">
                                    <div className="contacts-item-title">
                                        <h5>Phone</h5>
                                    </div>

                                    <div className="contacts-item-description">
                                        <p><a href="tel:+92 332 4999266">+92 332 4999266</a></p>
                                    </div>
                                </div>

                                <div className="contacts-item">
                                    <div className="contacts-item-title">
                                        <h5>General email</h5>
                                    </div>

                                    <div className="contacts-item-description">
                                        <p><a href="mailto:moiza1684@gmail.com">moiza1684@gmail.com</a></p>
                                    </div>
                                </div>

                                <div className="contacts-item">
                                    <div className="contacts-item-title">
                                        <h5>Business email</h5>
                                    </div>

                                    <div className="contacts-item-description">
                                        <p><a href="mailto:sharif_steel@hotmail.com">sharif_steel@hotmail.com</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                        <div className="contacts">
                            <div className="contacts-items">
                                <div className="contacts-item">
                                    <div className="contacts-item-title">
                                        <h6>Social</h6>
                                    </div>

                                    <div className="contacts-item-description">
                                        <p><a target='_blank' rel="noopener noreferrer" href="https://www.facebook.com/"><i className="fab fa-facebook text-secondary" />&nbsp;&nbsp;Facebook</a></p>
                                    </div>

                                    <div className="contacts-item-description">
                                        <p><a target='_blank' rel="noopener noreferrer" href="https://www.instagram.com/aandagroupofindustry"><i className="fab fa-instagram text-secondary" />&nbsp;&nbsp;Instagram</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="contact-form-shortcode">
                    <div className="title">
                        <h6 className="pb-0">Or you can write us here</h6>
                    </div>

                    <div className="spacer p-top-sm">
                        <ContactsForm />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contacts;
