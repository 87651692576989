import React, { Fragment, useEffect } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import PageTitle from '../blocks/about/PageTitle';
import AboutUs from '../blocks/home/AboutUs';
// import Services from '../blocks/home/Services';
import Contacts from '../blocks/home/Contacts';
import BottomContent from '../components/bottomContent';


const About = () => {
    useEffect(() => {
        document.body.classList.add('page');
        document.body.classList.add('bg-fixed');
        document.body.classList.add('bg-stripes');

        return () => {
            document.body.classList.remove('page');
            document.body.classList.remove('bg-fixed');
            document.body.classList.remove('bg-stripes');
        }
    }, []);

    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>About | A&A Group of Industries</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header />

            <main id="main" className="site-main">
                <PageTitle />

                <div id="page-content" className="spacer p-top-xl">
                    <div className="content">
                        <AboutUs />

                        {/* <Partners /> */}

                        {/* <section id="services" className="block spacer p-top-xl">
                            <div className="bg-secondary text-tertiary spacer p-top-xl p-bottom-xl">
                                <div className="wrapper">
                                    <div className="row gutter-width-md with-pb-lg">
                                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                            <div className="title">
                                                <h2>We offer</h2>
                                            </div>

                                            <div className="description">
                                                <p>Mic offers high quality services in various fields.</p>
                                            </div>
                                        </div>

                                        <Services />
                                    </div>
                                </div>
                            </div>
                        </section> */}

                        <section id="contacts" className="block spacer p-top-xl">
                            <div className="wrapper">
                                <div className="title">
                                    <h2>Contacts</h2>
                                </div>

                                <Contacts />
                            </div>
                        </section>
                    </div>
                </div>
            </main>

            <Footer />
            <BottomContent />
        </Fragment>
    );
};

export default About;
