import React from 'react';

const PageTitle = () => {
    return (
        <div className="title">
            <h1>404</h1>
        </div>
    );
};

export default PageTitle;
