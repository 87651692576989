import React, { Fragment, useEffect } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import PageTitle from '../blocks/home/PageTitle';
import BottomContent from '../components/bottomContent';
// import Partners from '../blocks/home/Partners';
// import Services from '../blocks/home/Services';

const Home = () => {
    useEffect(() => {
        document.body.classList.add('home');
        document.body.classList.add('bg-fixed');
        document.body.classList.add('bg-stripes');

        return () => {
            document.body.classList.remove('home');
            document.body.classList.remove('bg-fixed');
            document.body.classList.remove('bg-stripes');
        }
    }, []);

    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>A&A Group of Industries</title>
                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header />

            <main id="main" className="site-main">
                <div className="content">
                    <div className="clearfix">
                        <PageTitle />

                        <div id="img" className="block spacer p-top-xl">
                            <div className="img-size-1">
                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src="assets/img/bg/factory3.jpg" alt="Tools" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <section id="about-us" className="block spacer p-top-xl">
                            <div className="wrapper">
                                <div className="title">
                                    <h2>About</h2>
                                </div>

                                <div className="row gutter-width-md with-pb-md">
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                        <div className="description text-secondary">
                                            <p>Welcome to A & A Group of Industries, a legacy spanning over six decades, originating in 1960 with the foresight of Haji M. Sharif (Late), the father of our present directors. From humble beginnings as a small machinery engineering workshop, Haji M. Sharif's dedication brought his vision to fruition through years of hard work. </p>
                                        </div>
                                    </div>

                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <div className="description">
                                            <p>A pivotal moment came in 1979 when Haji M. Sharif's eldest son, M. Amjad Sharif, joined the company. After more than a decade of growth, Arshad Sharif joined the company in 1990. This marked a turning point as the company ventured into trading steel commodities, aligning with Pakistan Steel Mill for added strength.  </p>
                                            <p>The company thrived as an importer from 1990 to 2010 before diversifying into manufacturing steel pipes in 2010 and subsequently expanding into steel profiles. Notably, 2020 saw a leap into paper manufacturing using cutting-edge technology, with a focus on high-quality production through an imported unit. </p>
                                            <p>A & A Group of Industries is more than a business; it's a testament to resilience, innovation, and a commitment to excellence that has defined us for over 60 years. Our journey reflects a continuous pursuit of growth and adaptation, laying the foundation for a promising future.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* <Partners /> */}

                        {/* <section id="services" className="block spacer p-top-xl">
                            <div className="bg-secondary text-tertiary spacer p-top-xl p-bottom-xl">
                                <div className="wrapper">
                                    <div className="row gutter-width-md with-pb-lg">
                                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                            <div className="title">
                                                <h2>Services</h2>
                                            </div>

                                            <div className="description">
                                                <p>Mic offers high quality services in various fields.</p>
                                            </div>
                                        </div>

                                        <Services />
                                    </div>
                                </div>
                            </div>
                        </section> */}

                        <section id="contacts" className="block spacer p-top-xl">
                            <div className="wrapper">
                                <div className="title">
                                    <h2>Contacts</h2>
                                </div>

                                <div className="home-contact gutter-width-md with-pb-xl">
                                    <div className="gutter-width-md with-pb-lg">
                                        <div className="contacts">
                                            <div className="contacts-items">
                                                <div className="contacts-item">
                                                    <div className="contacts-item-title">
                                                        <h5>Locations</h5>
                                                    </div>
                                                    <div className="contacts-item-description">
                                                        <p><strong>Mangement Office: </strong>99 Railway Road, Naulakha, lahore, Lahore.</p>
                                                    </div>
                                                    <div className="contacts-item-description">
                                                        <p><strong>Factory: </strong>23-KM Sheikhupura Road. Mouza Killa. Bahind Siddique CNG, Lahore.</p>
                                                    </div>
                                                </div>

                                                <div className="contacts-item">
                                                    <div className="contacts-item-title">
                                                        <h5>Phone</h5>
                                                    </div>

                                                    <div className="contacts-item-description">
                                                        <p><a href="tel:+92 332 4999266">+92 332 4999266</a></p>
                                                    </div>
                                                </div>

                                                <div className="contacts-item">
                                                    <div className="contacts-item-title">
                                                        <h5>General email</h5>
                                                    </div>

                                                    <div className="contacts-item-description">
                                                        <p><a href="mailto:moiza1684@gmail.com">moiza1684@gmail.com</a></p>
                                                    </div>
                                                </div>

                                                <div className="contacts-item">
                                                    <div className="contacts-item-title">
                                                        <h5>Business email</h5>
                                                    </div>

                                                    <div className="contacts-item-description">
                                                        <p><a href="mailto:sharif_steel@hotmail.com">sharif_steel@hotmail.com</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </main>

            <Footer />
            <BottomContent />
        </Fragment>
    );
};

export default Home;
