import React, { Fragment, useEffect } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import PageTitle from '../blocks/contacts/PageTitle';
import ContactsBlock from '../blocks/home/Contacts';
import BottomContent from '../components/bottomContent';

const Contacts = () => {
    useEffect(() => {
        document.body.classList.add('page');
        document.body.classList.add('bg-fixed');
        document.body.classList.add('bg-stripes');

        return () => {
            document.body.classList.remove('page');
            document.body.classList.remove('bg-fixed');
            document.body.classList.remove('bg-stripes');
        }
    }, []);

    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Contacts | A&A Group of Industries</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header />

            <main id="main" className="site-main">
                <PageTitle />

                <div id="page-content" className="spacer p-top-xl">
                    <div className="content">
                        <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3399.0675018738348!2d74.33077537621742!3d31.577196744331136!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39191bf3c606a799%3A0xa1c4c4159b07edfc!2sA%20%26%20A%20group%20of%20Industries!5e0!3m2!1sen!2s!4v1705165609223!5m2!1sen!2s" height={'500px'}></iframe>

                        <div id="contacts" className="block spacer p-top-xl">
                            <div className="wrapper">
                                <ContactsBlock />
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <Footer />
            <BottomContent />
        </Fragment>
    );
};

export default Contacts;
