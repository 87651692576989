import React from 'react';
import { withRouter } from 'react-router-dom';

const ModalMenuPrimary = (props) => {
    return (
        <nav className="menu-primary">
            <ul className="nav">
                <li className={"nav-item " + (props.location.pathname === window.location.pathname && props.location.pathname === process.env.PUBLIC_URL + '/' ? ' current-nav-item' : '')}>
                    <a title="Home" href={process.env.PUBLIC_URL + '/'}>Home</a>
                </li>

                <li className={'nav-item' + (window.location.pathname.includes('/steel') ? ' current-nav-item' : '')}>
                    <a title="Steel" href={process.env.PUBLIC_URL + '/steel'}>Steel</a>
                </li>

                <li className={'nav-item' + (window.location.pathname.includes('/about') ? ' current-nav-item' : '')}>
                    <a title="About" href={process.env.PUBLIC_URL + '/about'}>About</a>
                </li>

                <li className={'nav-item' + (window.location.pathname.includes('/contact') ? ' current-nav-item' : '')}>
                    <a title="Contact" href={process.env.PUBLIC_URL + '/contact'}>Contact</a>
                </li>
            </ul>
        </nav>
    );
};

export default withRouter(ModalMenuPrimary);
