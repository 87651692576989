import React, { useState, useEffect } from 'react';
import ArrowUp from '../assets/icons/png/arrow.png';

export default function BottomContent() {
    const [isTop, setIsTop] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            if (scrollTop === 0) {
                setIsTop(true);
            } else {
                setIsTop(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => { window.removeEventListener('scroll', handleScroll); };
    }, []);
    return (
        <div className='bottom-content'>
            {!isTop && (
                <button onClick={() => window.scrollTo(0, 0)}>
                    <img src={ArrowUp} alt='scroll-to-top' />
                </button>
            )}
            <a href="https://wa.me/+923324999266" target='_blank' rel="noopener noreferrer">
                <img src='assets/img/icons/whatsapp.png' alt='stiky-whatsapp' />
            </a>
        </div>
    );
}