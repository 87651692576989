import React from 'react';
import HeaderLogo from '../logo/HeaderLogo';
import MenuModal from '../../components/modal/MenuModal';

const Header = () => {
    return (
        <header id="header" className="site-header">
            <div className="wrapper">
                <div className="header-content d-flex justify-content-between">
                    <div className="header-logo align-self-center">
                        <HeaderLogo />
                        <div className='tagline'>A&A Group of Industries</div>
                    </div>
                    <div className="header-menu align-self-center">
                        <MenuModal />
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;